<template>
  <div>
    <Employment
      @email="handleDone"
      @documents="handleDone"
      @approved="handleDone"
      @close="handleDone"
    />
    <!-- <p
      class="
        text-font-semibold text-brandPurple text-center
        cursor-pointer
        mt-8
      "
      @click="skipEmployment"
    >
      Skip, I will do it later
    </p> -->
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Employment from "@/components/Settings/WorkInfo/UpdateWorkInfo";
  import { CREDIT_QUALIFICATION_STEPS } from "@/utils/sharedData/home";
  export default {
    components: {
      Employment,
    },
    methods: {
      ...mapActions("dashboard", ["handleCreditQualificationStep", "handleShowReminder", "getDashboardData", 'showCreditQualificationStep']),
      ...mapActions('loading', ['setLoading']),
      handleDone() {
        this.setLoading({
          display: true,
          description: 'Please wait...'
        })
        this.getDashboardData().then(() => {
          this.showCreditQualificationStep(false);
          this.handleShowReminder(true);
          this.setLoading(false)
        })
        // this.handleCreditQualificationStep(CREDIT_QUALIFICATION_STEPS.bank);
      },
      skipEmployment() {
        this.handleDone();
      },
    },
  };
</script>

<style></style>
