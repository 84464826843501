var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Employment", {
        on: {
          email: _vm.handleDone,
          documents: _vm.handleDone,
          approved: _vm.handleDone,
          close: _vm.handleDone,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }